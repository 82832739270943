import QRCode from 'qrcode';
import './app.scss';
import { useRef, useState } from 'react';
function App() {
    const qrCodeRef = useRef<HTMLDivElement>(null);

    const [linkUrl, setLinkUrl] = useState('');
    const [size, setSize] = useState('200');
    const [fileLogo, setFileLogo] = useState('');
    const [qrCode, setQrCode] = useState<HTMLCanvasElement | null>(null);
    const [showDownload, setShowDownload] = useState(false);

    const changeLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const [file] = e.target.files as any;
            setFileLogo(URL.createObjectURL(file));
        }
    };

    const generateQRCode = async () => {
        if (!linkUrl) {
            alert('Vui lòng nhập đường dẫn liên kết');
            return;
        }
        setShowDownload(true);
        const canvasDom = document.getElementsByTagName('canvas');
        if (canvasDom.length > 0) {
            if (qrCodeRef.current) qrCodeRef.current.removeChild(canvasDom[0]);
        }
        let canvas = await QRCode.toCanvas(linkUrl, { errorCorrectionLevel: 'high', width: Number(size || 200), margin: 1 });

        if (fileLogo) {
            //adding a log at center
            const imgDim = { width: canvas.width / 5, height: canvas.height / 5 }; //logo dimention
            var context = canvas.getContext('2d');
            var imageObj = new Image();
            imageObj.src = fileLogo;
            imageObj.onload = function () {
                if (context) {
                    context.drawImage(imageObj, canvas.width / 2 - imgDim.width / 2, canvas.height / 2 - imgDim.height / 2, imgDim.width, imgDim.height);
                }
            };
        }

        setQrCode(canvas);
        if (qrCodeRef.current) {
            qrCodeRef.current.appendChild(canvas);
        }
    };

    const downloadQrCode = async () => {
        if (qrCode) {
            const imageSrc = qrCode.toDataURL('image/jpeg');
            const response = await fetch(imageSrc);

            const blobImage = await response.blob();

            const href = URL.createObjectURL(blobImage);

            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = 'qrcode';

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        }
    };

    return (
        <div className="app-page">
            <h2 className="title-page">Tạo QR CODE</h2>
            <div className="content-page">
                <div className="form-upload">
                    <div className="form-item">
                        <label htmlFor="">Đường dẫn liên kết</label>
                        <input className="input-text" type="text" placeholder="Nhập đường dẫn liên kết" onChange={(e) => setLinkUrl(e.target.value)} />
                    </div>
                    <div className="form-item">
                        <label htmlFor="">Chọn logo hiển thị</label>
                        <input type="file" onChange={(e) => changeLogo(e)} />
                    </div>
                    <div className="form-item">
                        <label htmlFor="">Kích thước QRCode (không nhập mặc định 200)</label>
                        <input className="input-text" type="text" placeholder="VD: 100, 200,..." onChange={(e) => setSize(e.target.value)} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button type="button" onClick={generateQRCode} className="btn-action">
                            Tạo QR Code
                        </button>
                    </div>
                </div>
                <div className="qr-review">
                    <div ref={qrCodeRef}></div>
                    {showDownload && (
                        <button type="button" className="btn-action" onClick={downloadQrCode}>
                            Tải QR Code
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
